import React, { SetStateAction, Dispatch, useRef,Ref, useState, useEffect } from 'react';
import * as Body from './Style'

type Props = {
    setText: any,
    placeholder?: string,
    disabled?: boolean,
    maxLenght?: number,
    code?: number,
    secret?: boolean,
    error?: boolean,
    ref?: Ref<any>
    align?: string,
    paddingRight?: number
}

const Status = {
    error: 1,
    warning: 2,
}

const CodeInput: React.FC <Props> = ({
    setText, placeholder, disabled, maxLenght, code, secret, error, ref, align, paddingRight
}) => {

    const [digito1, setDigito1] = useState("")
    const [digito2, setDigito2] = useState("")
    const [digito3, setDigito3] = useState("")
    const [digito4, setDigito4] = useState("")
    const [digito5, setDigito5] = useState("")
    const [digito6, setDigito6] = useState("")

    const number1 = useRef<any>()
    const number2 = useRef<any>()
    const number3 = useRef<any>()
    const number4 = useRef<any>()
    const number5 = useRef<any>()
    const number6 = useRef<any>()

    const field = (text: string, input: any) => {
        switch(input){
            case number1:
                setDigito1(text)
                setText(text.concat(digito2, digito3, digito4, digito5, digito6))
                if(text.length > 0) number2.current.focus()
                break
            case number2:
                setDigito2(text)
                setText(digito1.concat(text, digito3, digito4, digito5, digito6))
                text.length > 0? number3.current.focus():number1.current.focus()
                break
            case number3:
                setDigito3(text)
                setText(digito1.concat(digito2, text, digito4, digito5, digito6))
                text.length > 0? number4.current.focus():number2.current.focus()
                break
            case number4:
                setDigito4(text)
                setText(digito1.concat(digito2, digito3, text, digito5, digito6))
                text.length > 0? number5.current.focus():number3.current.focus()
                break
            case number5:
                setDigito5(text)
                setText(digito1.concat(digito2, digito3, digito4, text, digito6))
                text.length > 0? number6.current.focus():number4.current.focus()
                break
            case number6:
                setDigito6(text)
                setText(digito1.concat(digito2, digito3, digito4, digito5, text))
                if(text.length === 0) number5.current.focus()
                break
        }
    }

    return   <Body.DigitsContent>
                <Body.CodeInput autoComplete='one-time-code' ref={number1} maxLength={1} 
                    onChange={e => field(e.target.value, number1)}/>
                <Body.CodeInput autoComplete='one-time-code' ref={number2} maxLength={1} 
                    onChange={e => field(e.target.value, number2)}/>
                <Body.CodeInput autoComplete='one-time-code' ref={number3} maxLength={1} 
                    onChange={e => field(e.target.value, number3)}/>
                <Body.CodeInput autoComplete='one-time-code' ref={number4} maxLength={1} 
                    onChange={e => field(e.target.value, number4)}/>
                <Body.CodeInput autoComplete='one-time-code' ref={number5} maxLength={1} 
                    onChange={e => field(e.target.value, number5)}/>
                <Body.CodeInput autoComplete='one-time-code' ref={number6} maxLength={1} 
                    onChange={e => field(e.target.value, number6)}/>
            </Body.DigitsContent>
}

export default CodeInput;