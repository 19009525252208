import terno from './terno/reducer';
import { Intervencao, UnidadeHidraulicaType, MoendaProps, MoendaState, NotificationsType, Terno, HomeState, UHDefineLimitsProps, TernoDefineLimitsProps, ParametrizationTerno, ParametrizationUnidadeHidraulica } from './types';

export const intervencoes: Intervencao[] = [
    {area:'Transmissores', tipo: 'Troca', data_intervencao: 'Mon Jun 19 2023 13:39:00 GMT-0300 (Horário Padrão de Brasília)', nome: 'João', resolvido: true, id: 0},
    {resolvido: false,area: 'Válvulas', tipo:'Troca', data_intervencao: 'Mon Jun 19 2023 13:39:00 GMT-0300 (Horário Padrão de Brasília)', observacao: 'Manual - Carga e descarga:Esquerdo:1:',nome: 'Sistema', id: 1},
    {resolvido: false,area: 'Válvulas', tipo:'Troca', data_intervencao: 'Mon Jun 19 2023 13:39:00 GMT-0300 (Horário Padrão de Brasília)', observacao: 'Manual - Descarga:Direito:2:',nome: 'Sistema', id: 2}]

export const FakeInterventions = intervencoes

export const desempenho: Terno[] = [{transmissor_posic_direito: 0, transmissor_posic_esquerdo: 0, pressao_direita: {maxima: 2000, minima: 1500}, pressao_esquerda: {maxima: 2000, minima: 1500},
    zmax: 20,
    transmissor_pressao_direito:0, transmissor_pressao_esquerdo: 0, status: 0, setpoint_pressao: 0, funcionando: true, manual: true, terno_id: 1, nome_terno: '1'}]

const unidadeHidraulica: UnidadeHidraulicaType = {
    status: 1,
    manual: false,
    bomba: {
        autoteste_bomba_1: 3000,
        autoteste_bomba_2: 5000,
        autoteste_bomba_3: 0,
        data: '10/11/2021',
        situacao: 1, 
        transmissor_pressao_1: 500,
        transmissor_pressao_2: 400,
        transmissor_pressao_3: 0,
        pressao_max: 400    
        
    },
    oleo: {
        temperatura: 5,
        trans_umidade: 2,
        trans_fil_suj_1: 2.3,
        trans_fil_suj_2: 5,
        alarme_conjunto_filtro_1: 1,
        alarme_conjunto_filtro_2: 0,
        situacao: 1
    },
    valvulas: [{nome_terno: 'Terno 1', terno_id: 0}, {nome_terno: 'Terno 2', terno_id: 1}],
    transmissores: undefined
}



const notificacoes: NotificationsType[] = ([{id: 0,titulo: 'Filtro saturado', mensagem: 'O filtro do Óleo está ficando saturado, clique aqui pra mais datalhes', resolvido: true, responsavel: 'Beto Silva', data: '20/10/2021', hora: '10:00'},
                                            {id: 1,titulo: 'Filtro saturado 3', mensagem: 'O filtro do Óleo está ficando saturado, clique aqui pra mais datalhes', resolvido: false, responsavel: 'Beto Silva'},
                                            {id: 2,titulo: 'Filtro saturado 4', mensagem: 'O filtro do Óleo está ficando saturado, clique aqui pra mais datalhes', resolvido: false, responsavel: 'Beto Silva'},
                                            {id: 3,titulo: 'Filtro saturado 2', mensagem: 'O filtro do Óleo está ficando saturado, clique aqui pra mais datalhes', resolvido: true, responsavel: 'Beto Souza', data: '20/10/2021', hora: '12:00'}])

const user = {nome: 'João Paulo Cardoso de Souza', empresa: 'Tectrol Eireli', usina: 'Usina XYZ', cargo: 1}
export const parametros_uh: ParametrizationUnidadeHidraulica = {
    temp_max_oleo: 40, tempo_ligar_motor: 15, tempo_max_valvula_ligada: 20, altura_minima: 30, vazamento: 10, problemas_no_acumulador: 10, 
    nuvem: {temp_max_oleo: 40, tempo_ligar_motor: 15, tempo_max_valvula_ligada: 20, altura_minima: 30, vazamento: 10, problemas_no_acumulador: 10}
}
export const parametros_por_terno: ParametrizationTerno[] =  [{
  setpoint: 3300,
  pressao_maxima_le: 3850,
  pressao_maxima_ld: 3900,
  pressao_minima_le: 2400,
  pressao_minima_ld: 2500,
  maxima_tolerancia_superior_pressao: 1.059999942779541,
  maxima_tolerancia_inferior_pressao: 0.9399999976158142,
  tolerancia_superior_pressao: 1.0299999713897705,
  tolerancia_inferior_pressao: 0.9700000286102295,
  altura_maxima: 50,
  altura_terno_critico: 8,
  diferenca_altura_correcao: 4,
  fracao_diferenca_altura_correcao: 2,
  nuvem: {
    setpoint: 3300,
    pressao_maxima_le: 3850,
    pressao_maxima_ld: 3900,
    pressao_minima_le: 2400,
    pressao_minima_ld: 2500,
    maxima_tolerancia_superior_pressao: 1.059999942779541,
    maxima_tolerancia_inferior_pressao: 0.9399999976158142,
    tolerancia_superior_pressao: 1.0299999713897705,
    tolerancia_inferior_pressao: 0.9700000286102295,
    altura_maxima: 50,
    altura_terno_critico: 8,
    diferenca_altura_correcao: 4,
    fracao_diferenca_altura_correcao: 2,
    pressao_nitrogenio_le: 1234,
    pressao_nitrogenio_ld: 4321,
    pressao_maxima_mancal_ld: 1234,
    pressao_maxima_mancal_le: 4321,
    pressao_maxima_trabalho_acumulador_ld: 1234,
    pressao_maxima_trabalho_acumulador_le: 4321,
    pressao_trabalho_ld: 1234,
    pressao_trabalho_le: 4321
  }
  },]

export const dataMoenda: MoendaState[] = [{nome_usina: 'usina xyw', nivel_acesso: 0, intervencao: 1, nuvem_status: 1,
        desempenho: 1, unidadeHidraulica: unidadeHidraulica,  unidadeHidraulica2: unidadeHidraulica, ternos: [
            {nome_terno: '1', terno_id: 0, manual: false, funcionando: true, transmissor_posic_direito: 2000, transmissor_posic_esquerdo: 2000, transmissor_pressao_direito: 2000, transmissor_pressao_esquerdo: 2000, status: 32, setpoint_pressao: 100, pressao_direita: {maxima: 2000, minima: 1500}, pressao_esquerda:{ maxima: 2000, minima: 1500}, zmax: 20},
            {nome_terno: '1', terno_id: 0, manual: false, funcionando: true, transmissor_posic_direito: 2000, transmissor_posic_esquerdo: 2000, transmissor_pressao_direito: 2000, transmissor_pressao_esquerdo: 2000, status: 32, setpoint_pressao: 100, pressao_direita: {maxima: 2000, minima: 1500}, pressao_esquerda:{ maxima: 2000, minima: 1500}, zmax: 20},
            {nome_terno: '1', terno_id: 0, manual: false, funcionando: true, transmissor_posic_direito: 2000, transmissor_posic_esquerdo: 2000, transmissor_pressao_direito: 2000, transmissor_pressao_esquerdo: 2000, status: 32, setpoint_pressao: 100, pressao_direita: {maxima: 2000, minima: 1500}, pressao_esquerda:{ maxima: 2000, minima: 1500}, zmax: 20},
            {nome_terno: '1', terno_id: 0, manual: false, funcionando: true, transmissor_posic_direito: 2000, transmissor_posic_esquerdo: 2000, transmissor_pressao_direito: 2000, transmissor_pressao_esquerdo: 2000, status: 32, setpoint_pressao: 100, pressao_direita: {maxima: 2000, minima: 1500}, pressao_esquerda:{ maxima: 2000, minima: 1500}, zmax: 20},
            {nome_terno: '1', terno_id: 0, manual: false, funcionando: true, transmissor_posic_direito: 2000, transmissor_posic_esquerdo: 2000, transmissor_pressao_direito: 2000, transmissor_pressao_esquerdo: 2000, status: 32, setpoint_pressao: 100, pressao_direita: {maxima: 2000, minima: 1500}, pressao_esquerda:{ maxima: 2000, minima: 1500}, zmax: 20},
            {nome_terno: '1', terno_id: 0, manual: false, funcionando: true, transmissor_posic_direito: 2000, transmissor_posic_esquerdo: 2000, transmissor_pressao_direito: 2000, transmissor_pressao_esquerdo: 2000, status: 32, setpoint_pressao: 100, pressao_direita: {maxima: 2000, minima: 1500}, pressao_esquerda:{ maxima: 2000, minima: 1500}, zmax: 20},
            {nome_terno: '1', terno_id: 0, manual: false, funcionando: true, transmissor_posic_direito: 2000, transmissor_posic_esquerdo: 2000, transmissor_pressao_direito: 2000, transmissor_pressao_esquerdo: 2000, status: 32, setpoint_pressao: 100, pressao_direita: {maxima: 2000, minima: 1500}, pressao_esquerda:{ maxima: 2000, minima: 1500}, zmax: 20},
            {nome_terno: '1', terno_id: 0, manual: false, funcionando: true, transmissor_posic_direito: 2000, transmissor_posic_esquerdo: 2000, transmissor_pressao_direito: 2000, transmissor_pressao_esquerdo: 2000, status: 32, setpoint_pressao: 100, pressao_direita: {maxima: 2000, minima: 1500}, pressao_esquerda:{ maxima: 2000, minima: 1500}, zmax: 20}]},]

export const data: MoendaProps[] = [{id: 1, nome: 'Moenda 1', nivel_acesso: 0,status: 2, notificacoes: notificacoes, unidadeHidraulica: unidadeHidraulica,parametrizacao: {unidade_hidraulica: parametros_uh,
                        por_terno: [parametros_por_terno[0],parametros_por_terno[1],parametros_por_terno[2]]}, ternos: desempenho, intervencoes: intervencoes},
                    {id: 2, nome: 'Moenda 2', status: 2, nivel_acesso: 0, notificacoes: notificacoes, unidadeHidraulica: unidadeHidraulica, parametrizacao: {unidade_hidraulica: parametros_uh,
                        por_terno: [parametros_por_terno[0],parametros_por_terno[1],parametros_por_terno[2]]}, ternos: desempenho, intervencoes: intervencoes},
                    {id: 4, nome: 'Moenda 4', status: 1, nivel_acesso: 0, notificacoes: notificacoes, unidadeHidraulica: unidadeHidraulica, parametrizacao: {unidade_hidraulica: parametros_uh,
                        por_terno: [parametros_por_terno[0],parametros_por_terno[1],parametros_por_terno[2]]}, ternos: desempenho, intervencoes: intervencoes},
                    {id: 5, nome: 'Moenda 5', status: 2,  nivel_acesso: 0,notificacoes: notificacoes, unidadeHidraulica: unidadeHidraulica, parametrizacao: {unidade_hidraulica: parametros_uh,
                        por_terno: [parametros_por_terno[0],parametros_por_terno[1],parametros_por_terno[2]]}, ternos: desempenho, intervencoes: intervencoes},
                    {id: 6, nome: 'Moenda 6', status: 2,  nivel_acesso: 1,notificacoes: notificacoes, unidadeHidraulica: unidadeHidraulica, parametrizacao: {unidade_hidraulica: parametros_uh,
                        por_terno: [parametros_por_terno[0],parametros_por_terno[1],parametros_por_terno[2]]}, ternos: desempenho, intervencoes: intervencoes},
                    {id: 6, nome: 'Moenda 72', status: 2, nivel_acesso: 1, notificacoes: notificacoes, unidadeHidraulica: unidadeHidraulica, parametrizacao: {unidade_hidraulica: parametros_uh,
                        por_terno: [parametros_por_terno[0],parametros_por_terno[1],parametros_por_terno[2]]}, ternos: desempenho, intervencoes: intervencoes},
                    {id: 8, nome: 'Moenda 8', status: 1, nivel_acesso: 1, notificacoes: notificacoes, unidadeHidraulica: unidadeHidraulica, parametrizacao: {unidade_hidraulica: parametros_uh,
                        por_terno: [parametros_por_terno[0],parametros_por_terno[1],parametros_por_terno[2]]}, ternos: desempenho, intervencoes: intervencoes},
                    {id: 9, nome: 'Moenda 9', status: 1, nivel_acesso: 1, notificacoes: notificacoes, unidadeHidraulica: unidadeHidraulica, parametrizacao: {unidade_hidraulica: parametros_uh,
                        por_terno: [parametros_por_terno[0],parametros_por_terno[1],parametros_por_terno[2]]}, ternos: desempenho, intervencoes: intervencoes}]

export const homeFakeData: HomeState = {
    usuario: {
        p_nome: 'João Paulo',
        s_nome: 'Cardoso',
        empresa: 'Tectrol'
    },
    moendas: [{
        token: '',
        nome: 'Moenda 1',
        status: 1,
        qtd_ternos: 2,
        clp_status: 1,
        nuvem_status: 1
    }]
}

export const FakeUHDefine: UHDefineLimitsProps = {
    problemas_no_acumulador: 0,
    temp_max_oleo: 40,
    tempo_ligar_motor: 20,
    tempo_max_valvula_ligada: 20,
    altura_minima: 15,
    vazamento: 10,
    nuvem: {
        problemas_no_acumulador: 5,
        temp_max_oleo: 35,
        tempo_ligar_motor: 19,
        tempo_max_valvula_ligada: 20,
        altura_minima: 15,
        vazamento: 10,
    }
}

export const FakeTernoDefine: TernoDefineLimitsProps = {
    setpoint: 15,
    pressao_direita_max: 3000,
    pressao_direita_min: 3200,
    pressao_esquerda_max: 3000,
    pressao_esquerda_min: 3200,
    pressao_nitrogenio_le: 2000,
    pressao_nitrogenio_ld: 2000,
    pressao_maxima_mancal_ld: 2000,
    pressao_maxima_mancal_le: 2000,
    pressao_maxima_trabalho_acumulador_ld: 200,
    pressao_maxima_trabalho_acumulador_le: 2000,
    pressao_trabalho_ld: 2000,
    pressao_trabalho_le: 2000,
    dif_pressao_max: 2000,
    diferenca_altura: 20,
    direfenca_altura_max: 24,
    tolerancia_inf: 20,
    tolerancia_sup: 20,
    altura_max: 20,
    altura_min: 20,
    dif_altura_terno_critico: 4000,
    max_tolerancia_pressao: 4000,
    nuvem: {
        setpoint: 15,
        pressao_direita_max: 3000,
        pressao_direita_min: 3200,
        pressao_esquerda_max: 3000,
        pressao_esquerda_min: 3200,
        pressao_nitrogenio_le: 2000,
        pressao_nitrogenio_ld: 2000,
        pressao_maxima_mancal_ld: 2000,
        pressao_maxima_mancal_le: 2000,
        pressao_maxima_trabalho_acumulador_ld: 200,
        pressao_maxima_trabalho_acumulador_le: 2000,
        pressao_trabalho_ld: 2000,
        pressao_trabalho_le: 2000,
        dif_pressao_max: 2000,
        diferenca_altura: 20,
        direfenca_altura_max: 24,
        tolerancia_inf: 20,
        tolerancia_sup: 20,
        altura_max: 20,
        altura_min: 20,
        dif_altura_terno_critico: 4000,
        max_tolerancia_pressao: 4000
    }
}

export const dataChart = {
    autoteste: {
        unidade_hidraulica_1: {
          teste_bomba_1: [
            {
              timestamp: 1692214203426,
              valor: 4523
            }
          ],
          teste_bomba_2: [
            {
              timestamp: 1692214203426,
              valor: 4813
            }
          ]
        },
        unidade_hidraulica_2: null
    },
    temperatura_oleo: {
        unidade_hidraulica_1: [
          {
            _id: null,
            timestamp: 1692214203426,
            value: 36.12974493844168
          },
          {
            _id: null,
            timestamp: 1692214232782,
            value: 35.96367263793945
          },
          {
            _id: null,
            timestamp: 1692214260743,
            value: 35.90009798322405
          },
          {
            _id: null,
            timestamp: 1692214291303,
            value: 36.9114499773298
          },
          {
            _id: null,
            timestamp: 1692214321192,
            value: 38.6668826511928
          },
          {
            _id: null,
            timestamp: 1692214352414,
            value: 37.45161692301432
          },
          {
            _id: null,
            timestamp: 1692214383444,
            value: 35.97124099731445
          },
          {
            _id: null,
            timestamp: 1692214410313,
            value: 36.89263752528599
          },
          {
            _id: null,
            timestamp: 1692214440974,
            value: 41.80149296351841
          },
          {
            _id: null,
            timestamp: 1692214472161,
            value: 41.776192801339285
          },
          {
            _id: null,
            timestamp: 1692214501282,
            value: 38.3853383745466
          },
          {
            _id: null,
            timestamp: 1692214531006,
            value: 38.26402773175921
          },
          {
            _id: null,
            timestamp: 1692214561973,
            value: 37.63606916155134
          },
          {
            _id: null,
            timestamp: 1692214590179,
            value: 37.54005922589983
          },
          {
            _id: null,
            timestamp: 1692214621134,
            value: 36.86668831961496
          },
          {
            _id: null,
            timestamp: 1692214652286,
            value: 36.84787586757115
          },
          {
            _id: null,
            timestamp: 1692214682917,
            value: 36.71359198434012
          },
          {
            _id: null,
            timestamp: 1692214712630,
            value: 36.854363032749724
          },
          {
            _id: null,
            timestamp: 1692214744950,
            value: 37.42891184488932
          },
          {
            _id: null,
            timestamp: 1692214770891,
            value: 39.44923782348633
          }
        ],
        unidade_hidraulica_2: null
    },
    umidade_oleo: {
        unidade_hidraulica_1: [
          {
            _id: null,
            timestamp: 1692214203426,
            value: 0.3368703978402274
          },
          {
            _id: null,
            timestamp: 1692214232782,
            value: 0.32760298252105713
          },
          {
            _id: null,
            timestamp: 1692214260743,
            value: 0.3322366901806423
          },
          {
            _id: null,
            timestamp: 1692214291303,
            value: 0.32760298252105713
          },
          {
            _id: null,
            timestamp: 1692214321192,
            value: 0.3368703978402274
          },
          {
            _id: null,
            timestamp: 1692214352414,
            value: 0.3384149670600891
          },
          {
            _id: null,
            timestamp: 1692214383444,
            value: 0.3384149670600891
          },
          {
            _id: null,
            timestamp: 1692214410313,
            value: 0.3322366901806423
          },
          {
            _id: null,
            timestamp: 1692214440974,
            value: 0.3322366901806423
          },
          {
            _id: null,
            timestamp: 1692214472161,
            value: 0.32760298252105713
          },
          {
            _id: null,
            timestamp: 1692214501282,
            value: 0.3368703978402274
          },
          {
            _id: null,
            timestamp: 1692214531006,
            value: 0.3368703978402274
          },
          {
            _id: null,
            timestamp: 1692214561973,
            value: 0.32760298252105713
          },
          {
            _id: null,
            timestamp: 1692214590179,
            value: 0.3322366901806423
          },
          {
            _id: null,
            timestamp: 1692214621134,
            value: 0.32760298252105713
          },
          {
            _id: null,
            timestamp: 1692214652286,
            value: 0.32760298252105713
          },
          {
            _id: null,
            timestamp: 1692214682917,
            value: 0.3415041054998125
          },
          {
            _id: null,
            timestamp: 1692214712630,
            value: 0.3322366901806423
          },
          {
            _id: null,
            timestamp: 1692214744950,
            value: 0.3330089747905731
          },
          {
            _id: null,
            timestamp: 1692214770891,
            value: 0.3415041054998125
          }
        ],
        unidade_hidraulica_2: null
    },
    ternos: [
        [
          {
            _id: null,
            timestamp: 1692214228572,
            pressao_le: 2791.2330496651784,
            pressao_ld: 3656.1326729910716,
            deslocamento_le: 27.010435921805247,
            deslocamento_ld: 27.010435921805247
          },
          {
            _id: null,
            timestamp: 1692214255673,
            pressao_le: 2768.2776692708335,
            pressao_ld: 3652.7083740234375,
            deslocamento_le: 24.309253374735516,
            deslocamento_ld: 24.309253374735516
          },
          {
            _id: null,
            timestamp: 1692214286752,
            pressao_le: 2737.7137276785716,
            pressao_ld: 3639.117745535714,
            deslocamento_le: 22.019480296543666,
            deslocamento_ld: 22.019480296543666
          },
          {
            _id: null,
            timestamp: 1692214317071,
            pressao_le: 2719.308663504464,
            pressao_ld: 3677.6516462053573,
            deslocamento_le: 20.117115020751953,
            deslocamento_ld: 20.117115020751953
          },
          {
            _id: null,
            timestamp: 1692214348995,
            pressao_le: 2667.846749441964,
            pressao_ld: 3748.8531319754466,
            deslocamento_le: 22.495593752179825,
            deslocamento_ld: 22.495593752179825
          },
          {
            _id: null,
            timestamp: 1692214379253,
            pressao_le: 2574.2783203125,
            pressao_ld: 3825.559529622396,
            deslocamento_le: 20.68912347157796,
            deslocamento_ld: 20.68912347157796
          },
          {
            _id: null,
            timestamp: 1692214405080,
            pressao_le: 2586.506632486979,
            pressao_ld: 3804.735636393229,
            deslocamento_le: 20.64420986175537,
            deslocamento_ld: 20.64420986175537
          },
          {
            _id: null,
            timestamp: 1692214435970,
            pressao_le: 2639.5439801897323,
            pressao_ld: 3750.2154715401784,
            deslocamento_le: 23.101112910679408,
            deslocamento_ld: 23.101112910679408
          },
          {
            _id: null,
            timestamp: 1692214466778,
            pressao_le: 2878.837890625,
            pressao_ld: 3525.490059988839,
            deslocamento_le: 23.24304280962263,
            deslocamento_ld: 23.24304280962263
          },
          {
            _id: null,
            timestamp: 1692214497064,
            pressao_le: 2945.7022530691966,
            pressao_ld: 3429.1552734375,
            deslocamento_le: 26.222170693533762,
            deslocamento_ld: 26.222170693533762
          },
          {
            _id: null,
            timestamp: 1692214526855,
            pressao_le: 2960.5764508928573,
            pressao_ld: 3435.9945940290177,
            deslocamento_le: 23.96243040902274,
            deslocamento_ld: 23.96243040902274
          },
          {
            _id: null,
            timestamp: 1692214558436,
            pressao_le: 2871.664864676339,
            pressao_ld: 3564.1629464285716,
            deslocamento_le: 26.084415708269393,
            deslocamento_ld: 26.084415708269393
          },
          {
            _id: null,
            timestamp: 1692214585084,
            pressao_le: 2825.568777901786,
            pressao_ld: 3580.733084542411,
            deslocamento_le: 22.36038943699428,
            deslocamento_ld: 22.36038943699428
          },
          {
            _id: null,
            timestamp: 1692214616033,
            pressao_le: 2780.0009416852677,
            pressao_ld: 3696.9741559709823,
            deslocamento_le: 23.540816443307058,
            deslocamento_ld: 23.540816443307058
          },
          {
            _id: null,
            timestamp: 1692214647265,
            pressao_le: 2835.2162039620534,
            pressao_ld: 3567.527029854911,
            deslocamento_le: 21.091372762407577,
            deslocamento_ld: 21.091372762407577
          },
          {
            _id: null,
            timestamp: 1692214679531,
            pressao_le: 2813.7528250558034,
            pressao_ld: 3591.1033063616073,
            deslocamento_le: 23.08256040300642,
            deslocamento_ld: 23.08256040300642
          },
          {
            _id: null,
            timestamp: 1692214708455,
            pressao_le: 2932.7463727678573,
            pressao_ld: 3517.844412667411,
            deslocamento_le: 23.753710338047572,
            deslocamento_ld: 23.753710338047572
          },
          {
            _id: null,
            timestamp: 1692214739879,
            pressao_le: 2955.9056570870534,
            pressao_ld: 3503.025809151786,
            deslocamento_le: 22.889842442103795,
            deslocamento_ld: 22.889842442103795
          },
          {
            _id: null,
            timestamp: 1692214766736,
            pressao_le: 2990.1070556640625,
            pressao_ld: 3379.759969075521,
            deslocamento_le: 22.410714467366535,
            deslocamento_ld: 22.410714467366535
          },
          {
            _id: null,
            timestamp: 1692214797359,
            pressao_le: 2915.9816545758927,
            pressao_ld: 3489.013497488839,
            deslocamento_le: 19.753015109470912,
            deslocamento_ld: 19.753015109470912
          }
        ],
        [
          {
            _id: null,
            timestamp: 1692214228572,
            pressao_le: 3126.2221330915177,
            pressao_ld: 3444.6132463727677,
            deslocamento_le: 16.18460123879569,
            deslocamento_ld: 16.18460123879569
          },
          {
            _id: null,
            timestamp: 1692214255673,
            pressao_le: 3132.8900553385415,
            pressao_ld: 3447.2591145833335,
            deslocamento_le: 17.090909004211426,
            deslocamento_ld: 17.090909004211426
          },
          {
            _id: null,
            timestamp: 1692214286752,
            pressao_le: 3118.2707170758927,
            pressao_ld: 3449.867919921875,
            deslocamento_le: 16.113636425563268,
            deslocamento_ld: 16.113636425563268
          },
          {
            _id: null,
            timestamp: 1692214317071,
            pressao_le: 3052.6574358258927,
            pressao_ld: 3449.589913504464,
            deslocamento_le: 14.569109644208636,
            deslocamento_ld: 14.569109644208636
          },
          {
            _id: null,
            timestamp: 1692214348995,
            pressao_le: 2954.5711495535716,
            pressao_ld: 3446.3091866629466,
            deslocamento_le: 14.147727421351842,
            deslocamento_ld: 14.147727421351842
          },
          {
            _id: null,
            timestamp: 1692214379253,
            pressao_le: 2956.925089518229,
            pressao_ld: 3440.252970377604,
            deslocamento_le: 13.64989185333252,
            deslocamento_ld: 13.64989185333252
          },
          {
            _id: null,
            timestamp: 1692214405080,
            pressao_le: 2935.095703125,
            pressao_ld: 3424.521525065104,
            deslocamento_le: 11.126352945963541,
            deslocamento_ld: 11.126352945963541
          },
          {
            _id: null,
            timestamp: 1692214435970,
            pressao_le: 2925.1841866629466,
            pressao_ld: 3434.1874651227677,
            deslocamento_le: 9.724953719547816,
            deslocamento_ld: 9.724953719547816
          },
          {
            _id: null,
            timestamp: 1692214466778,
            pressao_le: 2949.3443429129466,
            pressao_ld: 3453.7046247209823,
            deslocamento_le: 11.858302320752825,
            deslocamento_ld: 11.858302320752825
          },
          {
            _id: null,
            timestamp: 1692214497064,
            pressao_le: 2995.9964425223216,
            pressao_ld: 3464.4363141741073,
            deslocamento_le: 15.572588103158134,
            deslocamento_ld: 15.572588103158134
          },
          {
            _id: null,
            timestamp: 1692214526855,
            pressao_le: 3171.873465401786,
            pressao_ld: 3240.266915457589,
            deslocamento_le: 15.199443135942731,
            deslocamento_ld: 15.199443135942731
          },
          {
            _id: null,
            timestamp: 1692214558436,
            pressao_le: 3205.6531110491073,
            pressao_ld: 3284.222237723214,
            deslocamento_le: 17.449675151279994,
            deslocamento_ld: 17.449675151279994
          },
          {
            _id: null,
            timestamp: 1692214585084,
            pressao_le: 3187.2758440290177,
            pressao_ld: 3290.5610700334823,
            deslocamento_le: 16.725649424961635,
            deslocamento_ld: 16.725649424961635
          },
          {
            _id: null,
            timestamp: 1692214616033,
            pressao_le: 3158.389334542411,
            pressao_ld: 3292.229248046875,
            deslocamento_le: 14.95640059879848,
            deslocamento_ld: 14.95640059879848
          },
          {
            _id: null,
            timestamp: 1692214647265,
            pressao_le: 3069.922607421875,
            pressao_ld: 3344.4140625,
            deslocamento_le: 10.9394713810512,
            deslocamento_ld: 10.9394713810512
          },
          {
            _id: null,
            timestamp: 1692214679531,
            pressao_le: 2924.572509765625,
            pressao_ld: 3525.1563895089284,
            deslocamento_le: 9.706632750374931,
            deslocamento_ld: 9.706632750374931
          },
          {
            _id: null,
            timestamp: 1692214708455,
            pressao_le: 2943.839425223214,
            pressao_ld: 3538.3902762276784,
            deslocamento_le: 11.496521268572126,
            deslocamento_ld: 11.496521268572126
          },
          {
            _id: null,
            timestamp: 1692214739879,
            pressao_le: 2977.619210379464,
            pressao_ld: 3500.4402204241073,
            deslocamento_le: 12.47704097202846,
            deslocamento_ld: 12.47704097202846
          },
          {
            _id: null,
            timestamp: 1692214766736,
            pressao_le: 3006.2601318359375,
            pressao_ld: 3438.3392740885415,
            deslocamento_le: 12.201027870178223,
            deslocamento_ld: 12.201027870178223
          },
          {
            _id: null,
            timestamp: 1692214797359,
            pressao_le: 3056.4941057477677,
            pressao_ld: 3359.7887137276784,
            deslocamento_le: 12.589053971426827,
            deslocamento_ld: 12.589053971426827
          }
        ],
        [
          {
            _id: null,
            timestamp: 1692214228572,
            pressao_le: 3301.793212890625,
            pressao_ld: 3338.0751604352677,
            deslocamento_le: 14.981447219848633,
            deslocamento_ld: 14.981447219848633
          },
          {
            _id: null,
            timestamp: 1692214255673,
            pressao_le: 3323.224161783854,
            pressao_ld: 3356.8601888020835,
            deslocamento_le: 15.625541051228842,
            deslocamento_ld: 15.625541051228842
          },
          {
            _id: null,
            timestamp: 1692214286752,
            pressao_le: 3316.3894391741073,
            pressao_ld: 3366.0442940848216,
            deslocamento_le: 15.534090859549385,
            deslocamento_ld: 15.534090859549385
          },
          {
            _id: null,
            timestamp: 1692214317071,
            pressao_le: 3210.6297433035716,
            pressao_ld: 3405.91259765625,
            deslocamento_le: 16.349722181047714,
            deslocamento_ld: 16.349722181047714
          },
          {
            _id: null,
            timestamp: 1692214348995,
            pressao_le: 2965.8310546875,
            pressao_ld: 3427.403738839286,
            deslocamento_le: 17.43831171308245,
            deslocamento_ld: 17.43831171308245
          },
          {
            _id: null,
            timestamp: 1692214379253,
            pressao_le: 2984.1388346354165,
            pressao_ld: 3414.206827799479,
            deslocamento_le: 16.828463236490887,
            deslocamento_ld: 16.828463236490887
          },
          {
            _id: null,
            timestamp: 1692214405080,
            pressao_le: 2996.0104166666665,
            pressao_ld: 3410.4119466145835,
            deslocamento_le: 16.83116881052653,
            deslocamento_ld: 16.83116881052653
          },
          {
            _id: null,
            timestamp: 1692214435970,
            pressao_le: 2988.100655691964,
            pressao_ld: 3388.369384765625,
            deslocamento_le: 15.706168855939593,
            deslocamento_ld: 15.706168855939593
          },
          {
            _id: null,
            timestamp: 1692214466778,
            pressao_le: 3002.335379464286,
            pressao_ld: 3365.2657296316966,
            deslocamento_le: 14.110621588570732,
            deslocamento_ld: 14.110621588570732
          },
          {
            _id: null,
            timestamp: 1692214497064,
            pressao_le: 3073.4813058035716,
            pressao_ld: 3363.2917829241073,
            deslocamento_le: 15.403061185564313,
            deslocamento_ld: 15.403061185564313
          },
          {
            _id: null,
            timestamp: 1692214526855,
            pressao_le: 3086.103515625,
            pressao_ld: 3364.6541573660716,
            deslocamento_le: 15.737940652029854,
            deslocamento_ld: 15.737940652029854
          },
          {
            _id: null,
            timestamp: 1692214558436,
            pressao_le: 3272.1004464285716,
            pressao_ld: 3282.8598981584823,
            deslocamento_le: 17.617114748273575,
            deslocamento_ld: 17.617114748273575
          },
          {
            _id: null,
            timestamp: 1692214585084,
            pressao_le: 3300.430908203125,
            pressao_ld: 3290.3108956473216,
            deslocamento_le: 17.88961056300572,
            deslocamento_ld: 17.88961056300572
          },
          {
            _id: null,
            timestamp: 1692214616033,
            pressao_le: 3264.176827566964,
            pressao_ld: 3294.453438895089,
            deslocamento_le: 17.186224256243026,
            deslocamento_ld: 17.186224256243026
          },
          {
            _id: null,
            timestamp: 1692214647265,
            pressao_le: 3224.8922293526784,
            pressao_ld: 3292.507254464286,
            deslocamento_le: 16.235157830374582,
            deslocamento_ld: 16.235157830374582
          },
          {
            _id: null,
            timestamp: 1692214679531,
            pressao_le: 3155.080880301339,
            pressao_ld: 3313.9150041852677,
            deslocamento_le: 15.100881440298897,
            deslocamento_ld: 15.100881440298897
          },
          {
            _id: null,
            timestamp: 1692214708455,
            pressao_le: 3050.961495535714,
            pressao_ld: 3376.8592703683034,
            deslocamento_le: 14.70222636631557,
            deslocamento_ld: 14.70222636631557
          },
          {
            _id: null,
            timestamp: 1692214739879,
            pressao_le: 3056.7443498883927,
            pressao_ld: 3371.604666573661,
            deslocamento_le: 14.610389573233467,
            deslocamento_ld: 14.610389573233467
          },
          {
            _id: null,
            timestamp: 1692214766736,
            pressao_le: 3282.5494384765625,
            pressao_ld: 3187.96630859375,
            deslocamento_le: 15.285984992980957,
            deslocamento_ld: 15.285984992980957
          },
          {
            _id: null,
            timestamp: 1692214797359,
            pressao_le: 3308.9384416852677,
            pressao_ld: 3199.369803292411,
            deslocamento_le: 16.116187504359655,
            deslocamento_ld: 16.116187504359655
          }
        ],
        [
          {
            _id: null,
            timestamp: 1692214228572,
            pressao_le: 2356.517264229911,
            pressao_ld: 3246.38330078125,
            deslocamento_le: 3.9958255972181047,
            deslocamento_ld: 3.9958255972181047
          },
          {
            _id: null,
            timestamp: 1692214255673,
            pressao_le: 2356.6006673177085,
            pressao_ld: 3246.38330078125,
            deslocamento_le: 4.035984834035237,
            deslocamento_ld: 4.035984834035237
          },
          {
            _id: null,
            timestamp: 1692214286752,
            pressao_le: 2357.0176827566966,
            pressao_ld: 3246.38330078125,
            deslocamento_le: 4.044063091278076,
            deslocamento_ld: 4.044063091278076
          },
          {
            _id: null,
            timestamp: 1692214317071,
            pressao_le: 2357.267892020089,
            pressao_ld: 3246.939383370536,
            deslocamento_le: 4.05983304977417,
            deslocamento_ld: 4.05983304977417
          },
          {
            _id: null,
            timestamp: 1692214348995,
            pressao_le: 2356.7674734933034,
            pressao_ld: 3247.2174246651784,
            deslocamento_le: 4.07374770300729,
            deslocamento_ld: 4.07374770300729
          },
          {
            _id: null,
            timestamp: 1692214379253,
            pressao_le: 2357.768310546875,
            pressao_ld: 3248.0052083333335,
            deslocamento_le: 4.116071542104085,
            deslocamento_ld: 4.116071542104085
          },
          {
            _id: null,
            timestamp: 1692214405080,
            pressao_le: 2357.4763997395835,
            pressao_ld: 3247.3564453125,
            deslocamento_le: 4.105789979298909,
            deslocamento_ld: 4.105789979298909
          },
          {
            _id: null,
            timestamp: 1692214435970,
            pressao_le: 2357.267892020089,
            pressao_ld: 3247.2174246651784,
            deslocamento_le: 4.16303334917341,
            deslocamento_ld: 4.16303334917341
          },
          {
            _id: null,
            timestamp: 1692214466778,
            pressao_le: 2356.7674734933034,
            pressao_ld: 3246.38330078125,
            deslocamento_le: 4.126391547066825,
            deslocamento_ld: 4.126391547066825
          },
          {
            _id: null,
            timestamp: 1692214497064,
            pressao_le: 2357.0176827566966,
            pressao_ld: 3247.2174246651784,
            deslocamento_le: 4.140074253082275,
            deslocamento_ld: 4.140074253082275
          },
          {
            _id: null,
            timestamp: 1692214526855,
            pressao_le: 2357.0176827566966,
            pressao_ld: 3247.4954659598216,
            deslocamento_le: 4.069341284888131,
            deslocamento_ld: 4.069341284888131
          },
          {
            _id: null,
            timestamp: 1692214558436,
            pressao_le: 2357.768310546875,
            pressao_ld: 3247.2174246651784,
            deslocamento_le: 4.082560334886823,
            deslocamento_ld: 4.082560334886823
          },
          {
            _id: null,
            timestamp: 1692214585084,
            pressao_le: 2357.768310546875,
            pressao_ld: 3247.4954659598216,
            deslocamento_le: 4.087430477142334,
            deslocamento_ld: 4.087430477142334
          },
          {
            _id: null,
            timestamp: 1692214616033,
            pressao_le: 2357.768310546875,
            pressao_ld: 3247.773507254464,
            deslocamento_le: 4.103664193834577,
            deslocamento_ld: 4.103664193834577
          },
          {
            _id: null,
            timestamp: 1692214647265,
            pressao_le: 2357.768310546875,
            pressao_ld: 3248.0515485491073,
            deslocamento_le: 4.172077996390207,
            deslocamento_ld: 4.172077996390207
          },
          {
            _id: null,
            timestamp: 1692214679531,
            pressao_le: 2357.768310546875,
            pressao_ld: 3247.773507254464,
            deslocamento_le: 4.206632682255337,
            deslocamento_ld: 4.206632682255337
          },
          {
            _id: null,
            timestamp: 1692214708455,
            pressao_le: 2357.768310546875,
            pressao_ld: 3247.773507254464,
            deslocamento_le: 4.238868236541748,
            deslocamento_ld: 4.238868236541748
          },
          {
            _id: null,
            timestamp: 1692214739879,
            pressao_le: 2357.768310546875,
            pressao_ld: 3248.0515485491073,
            deslocamento_le: 4.248144694737026,
            deslocamento_ld: 4.248144694737026
          },
          {
            _id: null,
            timestamp: 1692214766736,
            pressao_le: 2357.4763997395835,
            pressao_ld: 3247.3564453125,
            deslocamento_le: 4.225919882456462,
            deslocamento_ld: 4.225919882456462
          },
          {
            _id: null,
            timestamp: 1692214797359,
            pressao_le: 2357.267892020089,
            pressao_ld: 3246.6613420758927,
            deslocamento_le: 4.1832097598484586,
            deslocamento_ld: 4.1832097598484586
          }
        ],
        [
          {
            _id: null,
            timestamp: 1692214228572,
            pressao_le: 3030.6380789620534,
            pressao_ld: 3756.9992327008927,
            deslocamento_le: 23.034554617745535,
            deslocamento_ld: 23.034554617745535
          },
          {
            _id: null,
            timestamp: 1692214255673,
            pressao_le: 3026.435302734375,
            pressao_ld: 3756.2115071614585,
            deslocamento_le: 22.93235905965169,
            deslocamento_ld: 22.93235905965169
          },
          {
            _id: null,
            timestamp: 1692214286752,
            pressao_le: 3050.183035714286,
            pressao_ld: 3758.1112932477677,
            deslocamento_le: 24.11108534676688,
            deslocamento_ld: 24.11108534676688
          },
          {
            _id: null,
            timestamp: 1692214317071,
            pressao_le: 3163.3936941964284,
            pressao_ld: 3647.4027971540177,
            deslocamento_le: 24.714053835187638,
            deslocamento_ld: 24.714053835187638
          },
          {
            _id: null,
            timestamp: 1692214348995,
            pressao_le: 3341.6338239397323,
            pressao_ld: 3493.85107421875,
            deslocamento_le: 22.431354795183456,
            deslocamento_ld: 22.431354795183456
          },
          {
            _id: null,
            timestamp: 1692214379253,
            pressao_le: 3328.3489990234375,
            pressao_ld: 3516.185546875,
            deslocamento_le: 22.597943623860676,
            deslocamento_ld: 22.597943623860676
          },
          {
            _id: null,
            timestamp: 1692214405080,
            pressao_le: 3321.3104248046875,
            pressao_ld: 3530.5870768229165,
            deslocamento_le: 22.814935048421223,
            deslocamento_ld: 22.814935048421223
          },
          {
            _id: null,
            timestamp: 1692214435970,
            pressao_le: 3179.8526436941966,
            pressao_ld: 3612.0106375558034,
            deslocamento_le: 20.68274634225028,
            deslocamento_ld: 20.68274634225028
          },
          {
            _id: null,
            timestamp: 1692214466778,
            pressao_le: 3022.964669363839,
            pressao_ld: 3729.5862165178573,
            deslocamento_le: 10.274814469473702,
            deslocamento_ld: 10.274814469473702
          },
          {
            _id: null,
            timestamp: 1692214497064,
            pressao_le: 3008.7021135602677,
            pressao_ld: 3741.096365792411,
            deslocamento_le: 9.014378275190081,
            deslocamento_ld: 9.014378275190081
          },
          {
            _id: null,
            timestamp: 1692214526855,
            pressao_le: 2990.0468401227677,
            pressao_ld: 3761.7255859375,
            deslocamento_le: 6.537105696541922,
            deslocamento_ld: 6.537105696541922
          },
          {
            _id: null,
            timestamp: 1692214558436,
            pressao_le: 2994.1336844308034,
            pressao_ld: 3755.6090959821427,
            deslocamento_le: 9.953849792480469,
            deslocamento_ld: 9.953849792480469
          },
          {
            _id: null,
            timestamp: 1692214585084,
            pressao_le: 2999.8887765066966,
            pressao_ld: 3749.1033761160716,
            deslocamento_le: 12.293831280299596,
            deslocamento_ld: 12.293831280299596
          },
          {
            _id: null,
            timestamp: 1692214616033,
            pressao_le: 3022.4364188058034,
            pressao_ld: 3745.2389090401784,
            deslocamento_le: 15.020872116088867,
            deslocamento_ld: 15.020872116088867
          },
          {
            _id: null,
            timestamp: 1692214647265,
            pressao_le: 3093.304268973214,
            pressao_ld: 3746.0729631696427,
            deslocamento_le: 16.989100183759415,
            deslocamento_ld: 16.989100183759415
          },
          {
            _id: null,
            timestamp: 1692214679531,
            pressao_le: 3064.4178292410716,
            pressao_ld: 3743.0147530691966,
            deslocamento_le: 15.69202218736921,
            deslocamento_ld: 15.69202218736921
          },
          {
            _id: null,
            timestamp: 1692214708455,
            pressao_le: 3070.756661551339,
            pressao_ld: 3742.1806640625,
            deslocamento_le: 15.985157557896205,
            deslocamento_ld: 15.985157557896205
          },
          {
            _id: null,
            timestamp: 1692214739879,
            pressao_le: 3025.1610281808034,
            pressao_ld: 3737.760079520089,
            deslocamento_le: 13.524118968418666,
            deslocamento_ld: 13.524118968418666
          },
          {
            _id: null,
            timestamp: 1692214766736,
            pressao_le: 2990.885498046875,
            pressao_ld: 3753.973388671875,
            deslocamento_le: 11.24350643157959,
            deslocamento_ld: 11.24350643157959
          },
          {
            _id: null,
            timestamp: 1692214797359,
            pressao_le: 2955.0994001116073,
            pressao_ld: 3796.8953683035716,
            deslocamento_le: 11.494202205113002,
            deslocamento_ld: 11.494202205113002
          }
        ],
        [
          {
            _id: null,
            timestamp: 1692214228572,
            pressao_le: 2975.9510672433034,
            pressao_ld: 3666.141531808036,
            deslocamento_le: 13.602272578648158,
            deslocamento_ld: 13.602272578648158
          },
          {
            _id: null,
            timestamp: 1692214255673,
            pressao_le: 3006.4871826171875,
            pressao_ld: 3686.5390625,
            deslocamento_le: 14.954816023508707,
            deslocamento_ld: 14.954816023508707
          },
          {
            _id: null,
            timestamp: 1692214286752,
            pressao_le: 3035.475655691964,
            pressao_ld: 3697.02978515625,
            deslocamento_le: 15.460806982857841,
            deslocamento_ld: 15.460806982857841
          },
          {
            _id: null,
            timestamp: 1692214317071,
            pressao_le: 2985.5428292410716,
            pressao_ld: 3782.66064453125,
            deslocamento_le: 17.124304226466588,
            deslocamento_ld: 17.124304226466588
          },
          {
            _id: null,
            timestamp: 1692214348995,
            pressao_le: 2981.706124441964,
            pressao_ld: 3768.7039620535716,
            deslocamento_le: 18.620593752179825,
            deslocamento_ld: 18.620593752179825
          },
          {
            _id: null,
            timestamp: 1692214379253,
            pressao_le: 2992.3450520833335,
            pressao_ld: 3754.7842610677085,
            deslocamento_le: 19.037067413330078,
            deslocamento_ld: 19.037067413330078
          },
          {
            _id: null,
            timestamp: 1692214405080,
            pressao_le: 2997.1780598958335,
            pressao_ld: 3761.8229166666665,
            deslocamento_le: 19.200486818949383,
            deslocamento_ld: 19.200486818949383
          },
          {
            _id: null,
            timestamp: 1692214435970,
            pressao_le: 2995.4404296875,
            pressao_ld: 3753.301513671875,
            deslocamento_le: 17.724721908569336,
            deslocamento_ld: 17.724721908569336
          },
          {
            _id: null,
            timestamp: 1692214466778,
            pressao_le: 3023.409423828125,
            pressao_ld: 3735.73046875,
            deslocamento_le: 14.523191043308803,
            deslocamento_ld: 14.523191043308803
          },
          {
            _id: null,
            timestamp: 1692214497064,
            pressao_le: 2969.6121651785716,
            pressao_ld: 3758.5283203125,
            deslocamento_le: 7.751391547066825,
            deslocamento_ld: 7.751391547066825
          },
          {
            _id: null,
            timestamp: 1692214526855,
            pressao_le: 3005.810616629464,
            pressao_ld: 3744.7662179129466,
            deslocamento_le: 4.900974069322858,
            deslocamento_ld: 4.900974069322858
          },
          {
            _id: null,
            timestamp: 1692214558436,
            pressao_le: 3023.71533203125,
            pressao_ld: 3739.2892020089284,
            deslocamento_le: 4.79986081804548,
            deslocamento_ld: 4.79986081804548
          },
          {
            _id: null,
            timestamp: 1692214585084,
            pressao_le: 3004.1981026785716,
            pressao_ld: 3758.7785295758927,
            deslocamento_le: 5.574443408421108,
            deslocamento_ld: 5.574443408421108
          },
          {
            _id: null,
            timestamp: 1692214616033,
            pressao_le: 3078.624686104911,
            pressao_ld: 3765.089669363839,
            deslocamento_le: 8.61572367804391,
            deslocamento_ld: 8.61572367804391
          },
          {
            _id: null,
            timestamp: 1692214647265,
            pressao_le: 3122.8024553571427,
            pressao_ld: 3768.6761300223216,
            deslocamento_le: 11.129638263157435,
            deslocamento_ld: 11.129638263157435
          },
          {
            _id: null,
            timestamp: 1692214679531,
            pressao_le: 3112.126395089286,
            pressao_ld: 3759.3067452566966,
            deslocamento_le: 10.797309875488281,
            deslocamento_ld: 10.797309875488281
          },
          {
            _id: null,
            timestamp: 1692214708455,
            pressao_le: 3079.7367815290177,
            pressao_ld: 3742.3196149553573,
            deslocamento_le: 9.644480432782855,
            deslocamento_ld: 9.644480432782855
          },
          {
            _id: null,
            timestamp: 1692214739879,
            pressao_le: 3034.697195870536,
            pressao_ld: 3752.1615862165177,
            deslocamento_le: 9.149814401354108,
            deslocamento_ld: 9.149814401354108
          },
          {
            _id: null,
            timestamp: 1692214766736,
            pressao_le: 2991.7288411458335,
            pressao_ld: 3757.606241861979,
            deslocamento_le: 7.632034619649251,
            deslocamento_ld: 7.632034619649251
          },
          {
            _id: null,
            timestamp: 1692214797359,
            pressao_le: 2996.0243094308034,
            pressao_ld: 3764.8672223772323,
            deslocamento_le: 5.615723609924316,
            deslocamento_ld: 5.615723609924316
          }
        ]
    ],
    terno_critico: [
           [
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 5
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 7
              }
            ],
            [
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 5
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 7
              }
            ],
            [
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 5
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 7
              }
            ],
            [
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 5
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 7
              }
            ],
            [
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 5
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 7
              }
            ],
            [
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 5
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 7
              },
              {
                trueCount: 0,
                falseCount: 6
              },
              {
                trueCount: 0,
                falseCount: 7
              }
            ]
    ],
    pressao_filtro: {
        filtro_bomba_1: [
          {
            _id: null,
            timestamp: 1692214228572,
            value: 113.62958417619977
          },
          {
            _id: null,
            timestamp: 1692214255673,
            value: 113.94313176472981
          },
          {
            _id: null,
            timestamp: 1692214286752,
            value: 113.8983394077846
          },
          {
            _id: null,
            timestamp: 1692214317071,
            value: 1792.0309731619698
          },
          {
            _id: null,
            timestamp: 1692214348995,
            value: 2377.9166717529297
          },
          {
            _id: null,
            timestamp: 1692214379253,
            value: 113.62958399454753
          },
          {
            _id: null,
            timestamp: 1692214405080,
            value: 574.0426076253256
          },
          {
            _id: null,
            timestamp: 1692214435970,
            value: 2248.296224321638
          },
          {
            _id: null,
            timestamp: 1692214466778,
            value: 3818.8999372209823
          },
          {
            _id: null,
            timestamp: 1692214497064,
            value: 3184.718976702009
          },
          {
            _id: null,
            timestamp: 1692214526855,
            value: 1143.014683314732
          },
          {
            _id: null,
            timestamp: 1692214558436,
            value: 632.0309557233538
          },
          {
            _id: null,
            timestamp: 1692214585084,
            value: 1242.0777653285436
          },
          {
            _id: null,
            timestamp: 1692214616033,
            value: 1047.633573259626
          },
          {
            _id: null,
            timestamp: 1692214647265,
            value: 2849.8235059465683
          },
          {
            _id: null,
            timestamp: 1692214679531,
            value: 1733.8992854527064
          },
          {
            _id: null,
            timestamp: 1692214708455,
            value: 213.52578626360213
          },
          {
            _id: null,
            timestamp: 1692214739879,
            value: 609.9930637904575
          },
          {
            _id: null,
            timestamp: 1692214766736,
            value: 1326.6191317240398
          },
          {
            _id: null,
            timestamp: 1692214797359,
            value: 3376.1799937656947
          }
        ],
        filtro_bomba_2: [
          {
            _id: null,
            timestamp: 1692214228572,
            value: 154.82970973423548
          },
          {
            _id: null,
            timestamp: 1692214255673,
            value: 154.89242045084634
          },
          {
            _id: null,
            timestamp: 1692214286752,
            value: 155.0984649658203
          },
          {
            _id: null,
            timestamp: 1692214317071,
            value: 2504.876510620117
          },
          {
            _id: null,
            timestamp: 1692214348995,
            value: 2427.824460710798
          },
          {
            _id: null,
            timestamp: 1692214379253,
            value: 155.80170694986978
          },
          {
            _id: null,
            timestamp: 1692214405080,
            value: 613.7377141316732
          },
          {
            _id: null,
            timestamp: 1692214435970,
            value: 2296.3764059884206
          },
          {
            _id: null,
            timestamp: 1692214466778,
            value: 3869.8559221540177
          },
          {
            _id: null,
            timestamp: 1692214497064,
            value: 2678.545939854213
          },
          {
            _id: null,
            timestamp: 1692214526855,
            value: 1845.6744123186384
          },
          {
            _id: null,
            timestamp: 1692214558436,
            value: 675.2735988071987
          },
          {
            _id: null,
            timestamp: 1692214585084,
            value: 1893.7278791155134
          },
          {
            _id: null,
            timestamp: 1692214616033,
            value: 1088.5111977713448
          },
          {
            _id: null,
            timestamp: 1692214647265,
            value: 2897.339351109096
          },
          {
            _id: null,
            timestamp: 1692214679531,
            value: 1779.5338265555245
          },
          {
            _id: null,
            timestamp: 1692214708455,
            value: 256.49969482421875
          },
          {
            _id: null,
            timestamp: 1692214739879,
            value: 652.751953125
          },
          {
            _id: null,
            timestamp: 1692214766736,
            value: 2145.824378967285
          },
          {
            _id: null,
            timestamp: 1692214797359,
            value: 3428.9903411865234
          }
        ]
    }

}